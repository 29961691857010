import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";

class AutoLoginPage extends React.Component {
  render() {
    let { profile, navActions } = this.props;
    return (
      <Wrapper>
        {profile ? (
          <Message>
            {
              "您已成功登入，請返回Saizen的主頁，並請留意不要將您的登入連結提供給其他人!"
            }
          </Message>
        ) : (
          <Message>{"登入中..."}</Message>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.h2`
  padding: 15px;
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: (() => {
        return Selectors.getLoginUser(state);
      })()
    }),
    null
  )(AutoLoginPage)
);
